import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Navigation, { PATHS, PUBLIC_PATHS } from './navigation';
import store from './redux/store';
import { theme } from './theme';
import config from './config';
import { s360 } from './utils';
import 'src/localizations';

const App: React.FC = () => {
  const getConfig = async (): Promise<void> => {
    try {
      config.refresh().finally(() => {
        s360.init(config);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error load config: ', e);
      s360.init(config);
    }
  };

  const router = createBrowserRouter([{ path: '*', Component: Navigation }]);

  useEffect(() => {
    (async () => {
      await getConfig();

      if (!config.TOKEN && !Object.values(PUBLIC_PATHS).includes(window.location.pathname)) {
        window.location.href = PATHS.LOGIN_PAGE;
      } else if (config.TOKEN && Object.values(PUBLIC_PATHS).includes(window.location.pathname)) {
        // Technical Debt: A user can be logged but not have filled profile
        // window.location.href = PATHS.PROFILE_PAGE;
      } else if (window.location.pathname === '/') {
        window.location.href = PATHS.LOGIN_PAGE;
      }
    })();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
