import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Box, Container, Grid } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { themeColors } from 'src/theme';
import { Button, H1, P1R, FormikPassword } from 'src/components';
import { useDispatch } from 'react-redux';
import { changeUserPassword } from 'src/redux/user/actions';
import { PATHS } from 'src/navigation';
import { BlankWrapper } from '../wrappers';

const NewPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // getting access token from URL
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  const formik = useFormik({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema: Yup.object({
      password: Yup.string().min(8, 'Password must be longer than 7 characters').required(t('validations.required')),
      passwordConfirmation: Yup.string()
        .min(8, 'Password must be longer than 7 characters')
        .oneOf([Yup.ref('password')], t('validations.doNotMatch'))
        .required(t('validations.required')),
    }),
    onSubmit: async ({ password }) => {
      dispatch(changeUserPassword.init({ password, resetPasswordToken: token }));
      navigate(PATHS.LOGIN_PAGE);
    },
  });

  return (
    <BlankWrapper>
      <Container>
        <Grid container sx={{ padding: { xs: 0, md: 7 }, margin: '0 auto' }} maxWidth="sm">
          <Grid item xs={12} sx={{ pt: { xs: 0, md: 7 } }}>
            <H1>{t('newPasswordPage.title')}</H1>
            <Box
              sx={{
                margin: '40px 0',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  gap: 3,
                  marginBottom: 3,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormikPassword formik={formik} field="password" label="New Password" />
                <FormikPassword formik={formik} field="passwordConfirmation" label="Repeat Password" />
              </Box>
              <Button onClick={() => formik.handleSubmit()} sx={{ marginTop: 2, width: '100%', textTransform: 'none' }}>
                {t('newPasswordPage.send')}
              </Button>
            </Box>
            <P1R>
              {t('resetPasswordPage.backTo')}
              <Link
                component={RouterLink}
                to="/login"
                variant="body2"
                sx={{
                  ml: 0.5,
                  fontSize: '14px',
                  textDecoration: 'none',
                  color: themeColors.blue,
                  textTransform: 'capitalize',
                }}
              >
                {t('resetPasswordPage.linkLogin')}
              </Link>
            </P1R>
          </Grid>
        </Grid>
      </Container>
    </BlankWrapper>
  );
};

export default NewPassword;
