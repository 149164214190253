import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  LOGIN: createReduxAsyncType('LOGIN'),
  LOGOUT: createReduxAsyncType('LOGOUT'),
  VERIFY_CODE: createReduxAsyncType('VERIFY_CODE'),
  CREATE_USER: createReduxAsyncType('CREATE_USER'),
  RESEND: createReduxAsyncType('RESEND'),
  PATCH_USER: createReduxAsyncType('PATCH_USER'),
  EDIT_USER: createReduxAsyncType('EDIT_USER'),
  SET_USER_CURRENT_ROLE: createReduxAsyncType('SET_USER_CURRENT_ROLE'),
  LOAD_USER: createReduxAsyncType('LOAD_USER'),
  LOAD_USER_UUID: createReduxAsyncType('LOAD_USER_UUID'),
  ACTIVATE_USER: createReduxAsyncType('ACTIVATE_USER'),
  BLOCK_USER: createReduxAsyncType('BLOCK_USER'),
  GET_USER_INVITES: createReduxAsyncType('GET_USER_INVITES'),
  RESET_USER_PASSWORD: createReduxAsyncType('RESET_USER_PASSWORD'),
  CHANGE_USER_PASSWORD: createReduxAsyncType('CHANGE_USER_PASSWORD'),
  USER_UPDATE_PASSWORD: createReduxAsyncType('USER_UPDATE_PASSWORD'),
  USER_FAVORITE: createReduxAsyncType('USER_FAVORITE'),

  UPLOAD_USER_FILE: createReduxAsyncType('UPLOAD_USER_FILE'),
  DELETE_USER_FILE: createReduxAsyncType('DELETE_USER_FILE'),
  CANCEL_UPLOADING_USER_FILE: createReduxAsyncType('CANCEL_UPLOADING_USER_FILE'),

  SAVE_PROFILE: createReduxAsyncType('SAVE_PROFILE'),
  LOAD_PROFILE: createReduxAsyncType('LOAD_PROFILE'),

  UPDATE_USER_SETTINGS: createReduxAsyncType('UPDATE_USER_SETTINGS'),
  VERIFY_UNION_INFO: createReduxAsyncType('VERIFY_UNION_INFO'),
  UPDATE_USER_ROLES: createReduxAsyncType('UPDATE_USER_ROLES'),
  SET_USER_STATUS: createReduxAsyncType('SET_USER_STATUS'),
};

export { TYPES };
