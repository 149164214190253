import { createReduxAsyncAction } from '../utils/toolkit';
import { TYPES } from './constants';

export const login = createReduxAsyncAction(TYPES.LOGIN);
export const logout = createReduxAsyncAction(TYPES.LOGOUT);
export const verifyCode = createReduxAsyncAction(TYPES.VERIFY_CODE);
export const createUser = createReduxAsyncAction(TYPES.CREATE_USER);
export const resend = createReduxAsyncAction(TYPES.RESEND);
export const setUserCurrentRole = createReduxAsyncAction(TYPES.SET_USER_CURRENT_ROLE);
export const patchUser = createReduxAsyncAction(TYPES.PATCH_USER);
export const editUser = createReduxAsyncAction(TYPES.EDIT_USER);
export const loadUser = createReduxAsyncAction(TYPES.LOAD_USER);
export const loadUserUuid = createReduxAsyncAction(TYPES.LOAD_USER_UUID);
export const activateUser = createReduxAsyncAction(TYPES.ACTIVATE_USER);
export const blockUser = createReduxAsyncAction(TYPES.BLOCK_USER);
export const resetUserPassword = createReduxAsyncAction(TYPES.RESET_USER_PASSWORD);
export const changeUserPassword = createReduxAsyncAction(TYPES.CHANGE_USER_PASSWORD);
export const updateUserPassword = createReduxAsyncAction(TYPES.USER_UPDATE_PASSWORD);
export const favoriteUser = createReduxAsyncAction(TYPES.USER_FAVORITE);

export const uploadUserFile = createReduxAsyncAction(TYPES.UPLOAD_USER_FILE);
export const deleteUserFile = createReduxAsyncAction(TYPES.DELETE_USER_FILE);
export const cancelUploadingUserFile = createReduxAsyncAction(TYPES.CANCEL_UPLOADING_USER_FILE);

export const saveProfile = createReduxAsyncAction(TYPES.SAVE_PROFILE);
export const loadProfile = createReduxAsyncAction(TYPES.LOAD_PROFILE);
export const getUserInvites = createReduxAsyncAction(TYPES.GET_USER_INVITES);
export const updateUserSettings = createReduxAsyncAction(TYPES.UPDATE_USER_SETTINGS);
export const verifyUnionInfo = createReduxAsyncAction(TYPES.VERIFY_UNION_INFO);
export const updateUserRoles = createReduxAsyncAction(TYPES.UPDATE_USER_ROLES);
export const setUserStatus = createReduxAsyncAction(TYPES.SET_USER_STATUS);
