import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector, createErrorPayloadSelector } from '../@errors';
import { UserDataState } from './types';
import { ReduxState } from '../types';

export const selectUserData = (state: ReduxState): UserDataState => state.userData;

export const selectUser = createSelector(selectUserData, ({ user }) => user);

export const selectLogining = createLoadingSelector(TYPES.LOGIN.TYPE);
export const selectLoginingSuccess = createSuccessSelector(TYPES.LOGIN.TYPE);
export const selectLoginingError = createErrorSelector(TYPES.LOGIN.TYPE);

export const selectLogouting = createLoadingSelector(TYPES.LOGOUT.TYPE);
export const selectLogoutingSuccess = createSuccessSelector(TYPES.LOGOUT.TYPE);
export const selectLogoutingError = createErrorSelector(TYPES.LOGOUT.TYPE);

export const selectVerifyCode = createLoadingSelector(TYPES.VERIFY_CODE.TYPE);
export const selectVerifyCodeSuccess = createSuccessSelector(TYPES.VERIFY_CODE.TYPE);
export const selectVerifyCodeError = createErrorSelector(TYPES.VERIFY_CODE.TYPE);

export const selectCreatingUser = createLoadingSelector(TYPES.CREATE_USER.TYPE);
export const selectCreatingUserSuccess = createSuccessSelector(TYPES.CREATE_USER.TYPE);
export const selectCreatingUserErrors = createErrorPayloadSelector(TYPES.CREATE_USER.TYPE);

export const selectSetUserCurrentRole = createLoadingSelector(TYPES.SET_USER_CURRENT_ROLE.TYPE);
export const selectSetUserCurrentRoleSuccess = createSuccessSelector(TYPES.SET_USER_CURRENT_ROLE.TYPE);
export const selectSetUserCurrentRoleError = createErrorSelector(TYPES.SET_USER_CURRENT_ROLE.TYPE);

export const selectPatchingUser = createLoadingSelector(TYPES.PATCH_USER.TYPE);
export const selectPatchingUserSuccess = createSuccessSelector(TYPES.PATCH_USER.TYPE);
export const selectPatchingUserError = createErrorSelector(TYPES.PATCH_USER.TYPE);

export const selectIsUserLoading = createLoadingSelector(TYPES.LOAD_USER.TYPE);
export const selectIsUserSuccess = createSuccessSelector(TYPES.LOAD_USER.TYPE);
export const selectIsUserError = createErrorSelector(TYPES.LOAD_USER.TYPE);

export const selectActivatingUser = createLoadingSelector(TYPES.ACTIVATE_USER.TYPE);
export const selectActivatingUserSuccess = createSuccessSelector(TYPES.ACTIVATE_USER.TYPE);
export const selectActivatingUserError = createErrorSelector(TYPES.ACTIVATE_USER.TYPE);

export const selectBlockingUser = createLoadingSelector(TYPES.BLOCK_USER.TYPE);
export const selectBlockingUserSuccess = createSuccessSelector(TYPES.BLOCK_USER.TYPE);
export const selectBlockingUserError = createErrorSelector(TYPES.BLOCK_USER.TYPE);

export const selectGetUserInvites = createLoadingSelector(TYPES.GET_USER_INVITES.TYPE);
export const selectGetUserInvitesSuccess = createSuccessSelector(TYPES.GET_USER_INVITES.TYPE);
export const selectGetUserInvitesError = createErrorSelector(TYPES.GET_USER_INVITES.TYPE);

export const selectSavingProfile = createLoadingSelector(TYPES.SAVE_PROFILE.TYPE);
export const selectSaveProfileSuccess = createSuccessSelector(TYPES.SAVE_PROFILE.TYPE);
export const selectSaveProfileError = createErrorSelector(TYPES.SAVE_PROFILE.TYPE);

export const selectResettingUserPassword = createLoadingSelector(TYPES.RESET_USER_PASSWORD.TYPE);
export const selectSuccessResettingUserPassword = createSuccessSelector(TYPES.RESET_USER_PASSWORD.TYPE);
export const selectErrorResettingUserPassword = createErrorPayloadSelector(TYPES.RESET_USER_PASSWORD.TYPE);
export const selectResettingUserPasswordFailure = createErrorSelector(TYPES.RESET_USER_PASSWORD.TYPE);

export const selectChangingUserPassword = createLoadingSelector(TYPES.RESET_USER_PASSWORD.TYPE);
export const selectSuccessChangingUserPassword = createSuccessSelector(TYPES.RESET_USER_PASSWORD.TYPE);
export const selectErrorChangingUserPassword = createErrorSelector(TYPES.RESET_USER_PASSWORD.TYPE);

export const selectUpdatingUserPassword = createLoadingSelector(TYPES.USER_UPDATE_PASSWORD.TYPE);
export const selectSuccessUpdatingUserPassword = createSuccessSelector(TYPES.USER_UPDATE_PASSWORD.TYPE);
export const selectErrorUpdatingUserPassword = createErrorSelector(TYPES.USER_UPDATE_PASSWORD.TYPE);

export const selectUploadingUserFile = createLoadingSelector(TYPES.UPLOAD_USER_FILE.TYPE);
export const selectSuccessUploadingUserFile = createSuccessSelector(TYPES.UPLOAD_USER_FILE.TYPE);
export const selectErrorUploadingUserFile = createErrorSelector(TYPES.UPLOAD_USER_FILE.TYPE);

export const selectDeleteUserFile = createLoadingSelector(TYPES.DELETE_USER_FILE.TYPE);
export const selectSuccessDeleteUserFile = createSuccessSelector(TYPES.DELETE_USER_FILE.TYPE);
export const selectErrorDeleteUserFile = createErrorSelector(TYPES.DELETE_USER_FILE.TYPE);

export const selectUpdatingSettings = createLoadingSelector(TYPES.UPDATE_USER_SETTINGS.TYPE);
export const selectUpdateSettingsSuccess = createSuccessSelector(TYPES.UPDATE_USER_SETTINGS.TYPE);
export const selectUpdateSettingsError = createErrorSelector(TYPES.UPDATE_USER_SETTINGS.TYPE);

export const selectUserFavorite = createLoadingSelector(TYPES.USER_FAVORITE.TYPE);
export const selectUserFavoriteSuccess = createSuccessSelector(TYPES.USER_FAVORITE.TYPE);
export const selectUserFavoriteError = createErrorSelector(TYPES.USER_FAVORITE.TYPE);

export const selectUserUuid = createSelector(selectUserData, ({ userUuid }) => userUuid);

export const selectUserUuidLoading = createLoadingSelector(TYPES.LOAD_USER_UUID.TYPE);
export const selectUserUuidSuccess = createSuccessSelector(TYPES.LOAD_USER_UUID.TYPE);
export const selectUserUuidError = createErrorSelector(TYPES.LOAD_USER_UUID.TYPE);

export const selectResendingUserEmail = createLoadingSelector(TYPES.RESEND.TYPE);
export const selectSuccessResendingUserEmail = createSuccessSelector(TYPES.RESEND.TYPE);
export const selectResendEmailError = createErrorSelector(TYPES.RESEND.TYPE);
export const selectErrorsResendingUserEmail = createErrorPayloadSelector(TYPES.RESEND.TYPE);

export const selectVerifingUnionInfo = createLoadingSelector(TYPES.VERIFY_UNION_INFO.TYPE);
export const selectVerifingUnionInfoSuccess = createSuccessSelector(TYPES.VERIFY_UNION_INFO.TYPE);
export const selectVerifingUnionInfoError = createErrorSelector(TYPES.VERIFY_UNION_INFO.TYPE);

export const selectUpdatingUserRoles = createLoadingSelector(TYPES.UPDATE_USER_ROLES.TYPE);
export const selectUpdatingUserRolesSuccess = createSuccessSelector(TYPES.UPDATE_USER_ROLES.TYPE);
export const selectUpdatingUserRolesError = createErrorSelector(TYPES.UPDATE_USER_ROLES.TYPE);

export const selectSetUserStatusLoading = createLoadingSelector(TYPES.SET_USER_STATUS.TYPE);
export const selectSetUserStatusSuccess = createSuccessSelector(TYPES.SET_USER_STATUS.TYPE);
export const selectSetUserStatusError = createErrorSelector(TYPES.SET_USER_STATUS.TYPE);
